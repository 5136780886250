import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Access Recordings from our Mobile App",
  "path": "/Frequently_Asked_Question/Access_Recordings_from_Mobile_App/",
  "dateChanged": "2018-03-14",
  "author": "Mike Polinowski",
  "excerpt": "Can the pictures be retrieved with the mobile phone while on the move?",
  "image": "./FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "HD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='Access Recordings from our Mobile App' dateChanged='2018-03-14' author='Mike Polinowski' tag='INSTAR IP Camera' description='Can the pictures be retrieved with the mobile phone while on the move?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/Access_Recordings_from_Mobile_App/' locationFR='/fr/Frequently_Asked_Question/Access_Recordings_from_Mobile_App/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "access-recordings-from-our-mobile-app",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#access-recordings-from-our-mobile-app",
        "aria-label": "access recordings from our mobile app permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Access Recordings from our Mobile App`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: Can the pictures be retrieved with the mobile phone while on the move?`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: Yes, with the Android, Windows Phone & Metro, iPhone and iPad App InstarVision this is easily possible. The app is provided for free by INSTAR. You can then access the memory card at any time with the app and watch the videos taken.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Software/Android/InstarVision/Recording/"
        }}>{`InstarVision for Android`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/iPhone/Recording/"
        }}>{`InstarVision for iPhone`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/iPad/Recording/"
        }}>{`InstarVision for iPad`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Software/Windows/InstarVision/Windows_Phone/Recording/"
        }}>{`InstarVision for Windows Phone`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Software/Windows/InstarVision/Metro/Recording/"
        }}>{`InstarVision for Windows Metro`}</a></li>
    </ul>
    <p>{`You can also access it from the web browser without any problems and there are also some tools with which access is possible. See `}<a parentName="p" {...{
        "href": "/en/Motion_Detection/SD_Card_Access/"
      }}>{`here`}</a>{`.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      